import { getLayoutPosition } from '../helpers/gridLayoutHelper';
import { calculateScaleForFitOption, updateImageCropAndPosition } from '../helpers/objectSizingHelper';

//This is to change source on canvas during animations or when the image is clicked from layer panel
export const updateSourceOnCanvas = async (payload) => {
  var { ad, layerToUpdate, data, source } = payload;
  let fabricObject = layerToUpdate.fabricObject;
  if (!fabricObject) {
    return;
  }
  let imageSrc = source.imageUrl
  let { styles } = data;
  calculateScaleForFitOption(data, source.width, source.height);
  let adLayoutPosition = data.adLayoutPositions.find(layoutPosition => layoutPosition.layoutType == ad.layoutType);
  let layoutPosition = getLayoutPosition(
    { width: ad.width, height: ad.height },
    { width: source.width * styles.scaleX, height: source.height * styles.scaleY },
    adLayoutPosition.positionIndex,
    adLayoutPosition.positionOffset,
    !styles.hasOriginChanged
  );
  await new Promise((resolve, reject) => {
    fabricObject.setSrc(imageSrc, function (img) {
      img.set({
        ...styles,
        dirty: true,
        left: layoutPosition.left,
        top: layoutPosition.top,
        visible: !data.hidden
      });
      ad.canvas.renderAll();
      resolve();
    }, { crossOrigin: 'anonymous' });
  });
  updateImageCropAndPosition(fabricObject, data, adLayoutPosition, styles, ad, source);
  ad.canvas.renderAll();
}

export const getImageDimensions = (data) => {
  let width, height;
  if (!data.multipleSources || data.props.sources.length == 0) {
    width = data.props.width;
    height = data.props.height;
  }
  else {
    width = data.props.sources[0].width;
    height = data.props.sources[0].height;
  }
  return { width, height };
}