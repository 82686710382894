const CreativeBuilder = () => import(/* webpackChunkName: "creativeBuilder" */ 'Features/creativeBuilder/Main');
const VariantConfig = () => import(/* webpackChunkName: "variantConfig" */ 'Features/creativeBuilder/components/Variants/VariantConfig');
const ExportContainer = () => import(/* webpackChunkName: "exportContainer" */ 'Features/creativeBuilder/components/Export/ExportContainer');

const creativeBuilderRoute = {
  name: 'creativeBuilder',
  path: 'creativeBuilder',
  component: CreativeBuilder,
  meta: {
    requiresAuth: true,
    title: 'message.creativeBuilder',
    breadcrumb: 'message.creativeBuilder',
    customLayout: true
  },
  children: [
    {
      name: 'creativeBuilderVariant',
      path: 'variant',
      component: VariantConfig
    }
  ]
};

const creativeBuilderTemplateRoute = {
  name: 'creativeBuilderTemplate',
  path: 'creativeBuilder/:templateId',
  component: CreativeBuilder,
  meta: {
    requiresAuth: true,
    title: 'message.creativeBuilder',
    breadcrumb: 'message.creativeBuilder',
    customLayout: true
  },
  children: [
    {
      name: 'creativeBuilderTemplateVariant',
      path: 'variant',
      component: VariantConfig
    },
    {
      name: 'creativeBuilderExport',
      path: 'export',
      component: ExportContainer
    }
  ]
};

const creativeBuilderExportPreview = {
  name: 'creativeBuilderExportPreview',
  path: 'creativeBuilder/:templateId/export/:exportId/preview',
  component: CreativeBuilder,
  meta: {
    requiresAuth: true,
    title: 'message.creativeBuilder',
    breadcrumb: 'message.creativeBuilder',
    customLayout: true
  }
}

export default [
  creativeBuilderRoute,
  creativeBuilderTemplateRoute,
  creativeBuilderExportPreview
];
